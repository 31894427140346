import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, catchError, tap, throwError } from "rxjs";
import { SnackbarService } from "../shared/services/snackbar.service";
import { AuthenticationService } from "../modules/authentication/services/authentication.service";
import { HTTP_RESPONSE } from "../models/http-response.models";

export function responseInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const snackbarService = inject(SnackbarService);
  const authenticationService = inject(AuthenticationService);
  return next(req).pipe(
    tap(event => {
      if (event.type === HttpEventType.Response) {
        const res: HTTP_RESPONSE = event.body as HTTP_RESPONSE;
        const snackData = { message: res.message, type: 'danger'};
        // console.log("http req ", req.headers, req,  req.headers.has('skipErrorMsg'));
        if ( req.responseType !== 'blob' && req.headers.has('skipErrorMsg') && res.code !== 1) {
          snackbarService.openFromComponent(snackData)
        }
      }
    }),
    catchError((err: any) => {
      let errorMsg = 'Something went wrong';
      if (err instanceof HttpErrorResponse) {
        // Handle HTTP errors
        if (err.status === 401) {
          // Specific handling for unauthorized errors         
          authenticationService.logOutUser();
          //console.error('Unauthorized request:', err);
          errorMsg = 'Your session has timed out. Please login again.';          
       
          // You might trigger a re-authentication flow or redirect the user here
        } else {
          // Handle other HTTP error codes
          console.error('HTTP error:', err);
          errorMsg = 'HTTP error';
        }
      } else {
        // Handle non-HTTP errors
        console.error('An error occurred:', err);
        errorMsg = 'An error occurred';
      }

      const snackData = { message: errorMsg, type: 'danger'};

      snackbarService.openFromComponent(snackData)

      //snackbarService.open(errorMsg, 'X', {horizontalPosition: 'center', verticalPosition: 'top'});

      // Re-throw the error to propagate it further
      return throwError(() => err); 
    })
)}
